/** @jsx jsx */
import { jsx } from '@emotion/react'

import { notfound } from '../assets/styles/AboutTwo.styles'

const NotFoundContent = () => {
  return (
    <section  css={notfound}>
      <div>
        <h1>ops, Algo deu<br/> Errado! </h1>
       <p>Fique tranquilo, nossos técnicos estão trabalhando nisso.</p>
      </div>
    </section>
  )
}

export default NotFoundContent
